// eslint-disable-next-line import/no-extraneous-dependencies
import 'jquery';
import ng from 'angular';
import app from './app';

const angularRootContainerConfig = {
  template: `
    <app></app>
  `,
};

ng.module('angularRoot', [app]).component(
  'angularRootContainer',
  angularRootContainerConfig,
);

let angularAppNode: HTMLElement | null = null;

export function renderAngularApp() {
  /**
   * Angular should be bootstrapped only once
   */
  if (!angularAppNode) {
    angularAppNode = document.createElement('angular-root-container');
    ng.bootstrap(angularAppNode, ['angularRoot'], { strictDi: true });
  }

  return angularAppNode;
}
