import controller from './app.controller';

export const AppComponent = ($document) => {
  'ngInject';

  return {
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    replace: true,
    link: (scope) => {
      $document.on('click', () => {
        scope.$broadcast('$angularClick');
      });
      $document.on('mousedown', () => {
        scope.$broadcast('$angularMouseDown');
      });
      $document.on('mouseup', () => {
        scope.$broadcast('$angularMouseUp');
      });
      $document.on('keydown', (e) => {
        if (e.keyCode === 13) {
          const target = document.querySelector('.enter-target');
          if (target) {
            angular.element(target).trigger('click');
          }
        }
      });
    },
  };
};
