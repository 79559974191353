/* eslint-disable no-param-reassign */
import { Level, log } from 'cf-common/src/logger';
import { handleUnauthDuringSession } from './utils/handleUnauth';

const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
};

const SKIP_LOGGING_HTTP_ERROR_STATUSES = [422, 410];

const isConfigMethodPost = (config) => config.method === HTTP_METHODS.POST;
const isConfigMethodDelete = (config) => config.method === HTTP_METHODS.DELETE;
const isConfigMethodGet = (config) => config.method === HTTP_METHODS.GET;
const setMethodToGet = (config) => {
  config.method = HTTP_METHODS.GET;
  return config;
};
const isConfigMethodDeleteOrPost = (config) =>
  isConfigMethodDelete(config) || isConfigMethodPost(config);
const defaultMethodToGet = (config) => config.method || HTTP_METHODS.GET;

export function LogInterceptor($q) {
  'ngInject';

  return {
    request(config) {
      const { url, method } = config;

      if (url.indexOf('/api') === 0) {
        log({
          msg: 'Request to API',
          data: { url, method },
          level: Level.verbose,
        });
      }

      return config;
    },
    responseError(res) {
      const {
        config: { url, method },
        status,
        statusText,
      } = res;

      if (
        url.indexOf('/api') === 0 &&
        !SKIP_LOGGING_HTTP_ERROR_STATUSES.includes(status)
      ) {
        log({
          msg: 'Response error from API',
          data: { url, method, status, statusText },
          level: Level.warn,
        });
      }

      return $q.reject(res);
    },
  };
}

export function NormalizeHTTPConfigInterceptorFactory() {
  'ngInject';

  return {
    request(config) {
      const isMethodExistsAndString =
        config.method && config.method.toUpperCase;
      if (isMethodExistsAndString) {
        // upper case it;
        config.method = config.method.toUpperCase();
      }
      return config;
    },
  };
}

export function AuthInterceptor(
  $q,
  $window,
  $rootScope,
  $injector,
  StoreService,
) {
  'ngInject';

  return {
    request(config) {
      // eslint-disable-next-line chatfuel/no-use-localStorage
      const token = window.localStorage.getItem('token');
      config.headers.Authorization = `Bearer ${token}`;

      if ($rootScope.stateParams && $rootScope.stateParams.botId) {
        config.headers['Bot-Id'] = $rootScope.stateParams.botId;
      }

      return config;
    },
    response(res) {
      if (
        res.config.url.lastIndexOf('/user') === 0 &&
        res.data.result &&
        !res.data.result.terms_accepted &&
        !$rootScope.$tosShow
      ) {
        $rootScope.$broadcast('$tos', true);
      }
      return res;
    },
    responseError(rejection) {
      if (rejection.status === 401) {
        handleUnauthDuringSession();
      } else if (
        rejection.status === 403 &&
        !$rootScope.$tosShow &&
        rejection.data.result === "User didn't accept terms of service"
      ) {
        $rootScope.$broadcast('$tos', true);
      }
      return $q.reject(rejection);
    },
  };
}

export function SequenceInterceptor($q) {
  'ngInject';

  const defers = [];
  return {
    request(config) {
      if (!isConfigMethodDeleteOrPost(config)) {
        return config;
      }

      let previous = defers.shift();

      if (!previous) {
        previous = $q.defer();
        previous.resolve();
      }

      config.$$deferred = $q.defer();
      defers.push(config.$$deferred);

      return previous.promise.then(() => config);
    },

    response(res) {
      if (res.config.$$deferred) {
        res.config.$$deferred.resolve();
      }

      return res;
    },

    responseError(res) {
      if (res.config && res.config.$$deferred) {
        res.config.$$deferred.resolve();
      }

      return $q.reject(res);
    },
  };
}

export function PreCacheInterceptor($q, CacheControlService) {
  'ngInject';

  return ['URL', 'Blob', 'WeakMap'].every(
    (feature) => typeof window[feature] !== 'undefined',
  )
    ? (() => {
        const storage = {
          get: {},
          post: {},
        };

        CacheControlService.preInit({
          getPreCache() {
            return storage;
          },
        });

        const genCacheSign = (config) => {
          let sign = config.url;
          if (config.data && config.data.plugin_id) {
            if (config.data.id) {
              sign += `##${config.data.id}`;
            } else {
              sign += `##${Math.random()}`;
            }
          }
          if (config.params) {
            sign += `##${JSON.stringify(config.params)}`;
          }
          return sign;
        };

        return {
          request(config) {
            const { url } = config;
            if (config.cacheOptions && config.cacheOptions.skipPreCache) {
              return config;
            }
            if (url.indexOf('http') !== 0 && url.indexOf('/api/') !== 0) {
              return config;
            }
            if (isConfigMethodGet(config)) {
              const cacheSign = genCacheSign(config);
              config.preCache = true;
              let cache = storage.get[cacheSign];

              if (cache) {
                config.usePreCache = true;
                return cache.$$deferred.promise.then(() => {
                  config.url = cache.lUrl;
                  return config;
                });
              }
              cache = {
                $$deferred: $q.defer(),
              };

              storage.get[cacheSign] = cache;

              let revalidateCount = 10;

              const revalidatePreCache = () => {
                revalidateCount--;
                clearTimeout(cache.timeout);
                cache.timeout = setTimeout(() => {
                  const { lUrl } = cache;
                  if (lUrl || cache.error || revalidateCount < 0) {
                    window.URL.revokeObjectURL(lUrl);
                    delete storage.get[cacheSign];
                  } else {
                    revalidatePreCache();
                  }
                }, 2000);
              };

              revalidatePreCache();
              return config;
            }
            if (isConfigMethodDeleteOrPost(config)) {
              const cacheSign = genCacheSign(config);

              config.prePostCache = true;
              let cache = storage.post[cacheSign];

              if (!cache) {
                cache = {
                  $$deferreds: [],
                  timeout: null,
                };
                storage.post[cacheSign] = cache;
              }

              const def = $q.defer();
              cache.$$deferreds.push(def);

              clearTimeout(cache.timeout);
              cache.timeout = setTimeout(() => {
                def.resolve();
              }, 500);

              return def.promise.then(() => {
                const cache = storage.post[cacheSign];
                if (cache && cache.lUrl) {
                  config.url = cache.lUrl;
                  setMethodToGet(config);
                }
                return config;
              });
            }
            return config;
          },

          response(response) {
            const { config } = response;

            if (config.preCache) {
              const cacheSign = genCacheSign(config);
              const cache = storage.get[cacheSign];
              if (!config.usePreCache && cache) {
                cache.lUrl = window.URL.createObjectURL(
                  new window.Blob([JSON.stringify(response.data)], {
                    type: 'application/json',
                  }),
                );
                cache.$$deferred.resolve();
              }
            } else if (config.prePostCache) {
              const cacheSign = genCacheSign(config);
              const cache = storage.post[cacheSign];
              if (cache && !cache.lUrl) {
                cache.lUrl = window.URL.createObjectURL(
                  new window.Blob([JSON.stringify(response.data)], {
                    type: 'application/json',
                  }),
                );

                cache.$$deferreds.forEach((item) => item.resolve());

                setTimeout(() => {
                  window.URL.revokeObjectURL(cache.lUrl);
                  delete storage.post[cacheSign];
                }, 10);
              }
            }

            return response;
          },

          responseError(res) {
            const { config } = res;
            if (config.preCache) {
              const cache = storage.get[genCacheSign(config)];
              if (cache) {
                cache.error = true;
              }
            }

            return $q.reject(res);
          },
        };
      })()
    : {};
}

export function CacheInterceptor(CacheControlService, StoreService) {
  'ngInject';

  return ['URL', 'Blob', 'WeakMap'].every(
    (feature) => typeof window[feature] !== 'undefined',
  )
    ? (() => {
        const cache = {
          rules: {
            get(config) {
              if (
                config.url.indexOf('http') !== 0 &&
                config.url.indexOf('/') !== 0
              ) {
                return false;
              }
              const url = config.url.replace(StoreService.getApiUrl(), '');
              let rule = cache.rules.contents.find(
                (rule) =>
                  url === rule.url &&
                  config.method === defaultMethodToGet(rule),
              );
              if (
                !rule &&
                config.cacheOptions &&
                config.cacheOptions.need &&
                !config.useCache
              ) {
                config.cacheOptions.need = false;
                rule = {
                  url,
                  method: config.method,
                };

                if (config.cacheOptions.lifetime) {
                  rule.lifetime = config.cacheOptions.lifetime;
                }

                cache.rules.contents.push(rule);
              }

              return rule;
            },
            contents: [
              {
                url: '/logout',
                method: 'post',
                onInsert: () => true,
              },
            ],
          },
          contents: new WeakMap(),
          get(rule) {
            return rule ? cache.contents.get(rule) : null;
          },
          set(rule, data) {
            const url = window.URL.createObjectURL(
              new window.Blob([JSON.stringify(data)], {
                type: 'application/json',
              }),
            );
            rule.time = Date.now();
            cache.contents.set(rule, url);
            return url;
          },
          remove(rule) {
            const url = cache.contents.get(rule);
            if (url) {
              window.URL.revokeObjectURL(url);
              cache.contents.delete(rule);
            }
            return url;
          },
          clear() {
            cache.rules.contents.forEach(cache.remove);
          },
        };

        CacheControlService.init({
          removeItem(url) {
            const rule = cache.rules.get({ url, method: HTTP_METHODS.GET });
            if (rule) {
              cache.remove(rule);
            }
          },
          clear() {
            cache.contents = new WeakMap();
          },
          getCache() {
            return cache;
          },
        });

        return {
          request(config) {
            config.cacheOptions = config.cacheOptions || {};
            const rule = cache.rules.get(config);

            if (rule) {
              if (
                config.cacheOptions.refresh ||
                (rule.lifetime &&
                  rule.time &&
                  Date.now().valueOf() - rule.time.valueOf() > rule.lifetime)
              ) {
                cache.remove(rule);
              } else {
                const url = cache.get(rule);
                if (url) {
                  config.url = url;
                  config.useCache = true;
                }
              }
            }

            return config;
          },

          response(response) {
            const rule = cache.rules.get(response.config);
            if (rule && !cache.get(rule)) {
              if (!rule.onInsert || !rule.onInsert(response)) {
                cache.set(rule, response.data);
              }
            }
            return response;
          },
        };
      })()
    : {};
}

// preliminary serialize get params (for Cache keys)
export function GetParamsSerializeInterceptor($httpParamSerializer) {
  'ngInject';

  return {
    request(config) {
      if (config.method === HTTP_METHODS.GET && config.params) {
        config.url = `${config.url}${
          config.url.indexOf('?') === -1 ? '?' : '&'
        }${$httpParamSerializer(config.params)}`;
        config.params = null;
      }
      return config;
    },
  };
}
