/**
 * Сервис управления глобальным кешем
 *
 * Возможности:
 *  - Прелоадить (тихо - после загрузки приложения, когда очередь на загрузку свободна) данные в кеш указав ссылки в массиве this.preloadList
 *  - Удалять запись из кеша  CacheControlService.removeItem(url)
 *  - Очистить кеш вообще  CacheControlService.clear()
 *  - Получить объект кеша CacheControlService.getCache()
 *
 */

import { getCurrencies } from '@utils/Data/Currency';

const currenciesQuery = 'currenciesQuery';
export class CacheControl {
  constructor($injector, StoreService) {
    'ngInject';

    this.$injector = $injector;
    this.StoreService = StoreService;

    this.preloadList = ['/validator_config', currenciesQuery];

    this.loadedCount = 0;
  }

  init(param) {
    this.removeItem = param.removeItem;
    this.clear = param.clear;
    this.getCache = param.getCache;

    window._cf_cache_clear = this.clear;
    window._cf_cache_get = this.getCache;
  }

  preInit(param) {
    this.getPreCache = param.getPreCache;
  }

  startPreload() {
    const $http = this.$injector.get('$http');
    const wait = () => {
      const preCache = this.getPreCache();
      if (
        // eslint-disable-next-line chatfuel/no-use-localStorage
        window.localStorage.getItem('token') &&
        Object.keys(preCache.get).length === 0 &&
        Object.keys(preCache.post).length === 0
      ) {
        if (this.preloadList[this.loadedCount] === currenciesQuery) {
          getCurrencies();
        } else {
          $http({
            method: 'get',
            url:
              this.StoreService.getApiUrl() +
              this.preloadList[this.loadedCount],
            cacheOptions: {
              need: true,
            },
          });
        }

        this.loadedCount++;
      }

      if (this.loadedCount < this.preloadList.length) {
        setTimeout(() => {
          wait();
        }, 500);
      }
    };

    setTimeout(() => {
      wait();
    }, 2000);
  }
}
