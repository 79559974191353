import { BehaviorSubject } from 'rxjs/BehaviorSubject';

const TAB_KEY_CODE = 9;
/**
 * It's better to set "focus-not-visible" className than "focus-visible"
 * className, because this way we would check for an explicit className
 * before _turning off_ focus outline in components. If something goes wrong,
 * it's better not to turn off focus outline by default.
 */
const FOCUS_NOT_VISIBLE_CLASSNAME = 'focus-not-visible';

const subject = new BehaviorSubject({ focusVisible: false });

function handleKeyDown(event: KeyboardEvent) {
  if (event.which === TAB_KEY_CODE) {
    subject.next({ focusVisible: true });
  }
}

function handleMouseClick() {
  subject.next({ focusVisible: false });
}

export function onlyShowFocusOnKeyboardNavigation(
  containerElement: HTMLElement = document.documentElement!,
) {
  const removeListeners = () => {
    containerElement.removeEventListener('keydown', handleKeyDown);
    containerElement.removeEventListener('click', handleMouseClick);
  };

  const subscription = subject.subscribe(({ focusVisible }) => {
    removeListeners();

    if (focusVisible) {
      containerElement.classList.remove(FOCUS_NOT_VISIBLE_CLASSNAME);
      containerElement.addEventListener('click', handleMouseClick);
    } else {
      containerElement.classList.add(FOCUS_NOT_VISIBLE_CLASSNAME);
      containerElement.addEventListener('keydown', handleKeyDown);
    }
  });

  return () => {
    removeListeners();
    subscription.unsubscribe();
  };
}
