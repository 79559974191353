import React from 'react';
import { renderAngularApp } from './angular-root';

export class AngularApp extends React.Component<{}> {
  node?: HTMLDivElement;

  componentDidMount() {
    if (!this.node) {
      return;
    }

    const angularAppNode = renderAngularApp();
    this.node.appendChild(angularAppNode);
  }

  shouldComponentUpdate() {
    /**
     * This is important, because
     * angular manages what happens inside its DOM element,
     * so it shouldn't be touched by react after initial rendering.
     */
    return false;
  }

  mount = (node: HTMLDivElement) => {
    this.node = node;
  };

  render() {
    return (
      <div style={{ height: 0 }} ref={this.mount} id="angular-root"/>
    );
  }
}
