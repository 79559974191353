import { Level } from 'cf-common/src/logger';
import { IS_DEBUG } from 'cf-common/src/environment';
import {
  LogInterceptor,
  AuthInterceptor,
  PreCacheInterceptor,
  CacheInterceptor,
  SequenceInterceptor,
  GetParamsSerializeInterceptor,
  NormalizeHTTPConfigInterceptorFactory,
} from './app.interceptors';

export const AppConfig = ($httpProvider, $logProvider) => {
  'ngInject';

  $logProvider.debugEnabled(IS_DEBUG);
  $httpProvider.interceptors.push(
    LogInterceptor,
    NormalizeHTTPConfigInterceptorFactory,
    GetParamsSerializeInterceptor,
    CacheInterceptor,
    PreCacheInterceptor,
    AuthInterceptor,
    SequenceInterceptor,
  );
};
