/* eslint-disable-next-line import/order */
import './assets2/less/main.less';
import ng from 'angular';
import ngCookies from 'angular-cookies';
import ngSanitize from 'angular-sanitize';
import ngI18next from 'ng-i18next';
import { getAdminFeatures } from '@utils/Data/Admin';
import { Level, log } from 'cf-common/src/logger';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';
import Common from './common';
import Components from './components';
import Constants from './constants';
import Pages from './pages';
import {
  canView,
  canEdit,
  onPermissionsUpdate,
  loadPermissionsMemoized,
} from './common/services/RoleService';
import { AppConfig } from './app.config';
import { AppComponent } from './app.component';
import { Store } from './app.services';
import { CacheControl } from './cache-control-service/service';
import ui from './ui';
import modernUi from './modern-ui';
import modernComponents from './modern-components';
import { preloadServerStorage } from './utils/ServerStorage';
import { setAngularContext } from './common/services/ApolloService.angular';
import { lazyInjector } from './utils/angularLazyInjector';
import { setAngularRouterContext } from './utils/AngularRouting';
import { I18nEvent, i18nEmitter } from './i18n';

const subscribeForLanguageChange = (scope) =>
  window.i18next.on('languageChanged', () => {
    scope.$broadcast('i18nextLanguageChange');
    scope.$digest();
  });

export default ng
  .module('app', [
    ngSanitize,
    ngI18next,
    Constants,
    Common,
    Components,
    Pages,
    ui,
    ngCookies,
    modernUi,
    modernComponents,
  ])
  .directive('app', AppComponent)
  .service('StoreService', Store)
  .service('CacheControlService', CacheControl)
  .config(AppConfig)
  .constant(
    'API_ERROR_MSG',
    'Sorry, something went wrong. Please try again later.',
  )
  .factory('$exceptionHandler', function $exceptionHandler() {
    'ngInject';

    return function myAngularExceptionHandler(exception) {
      log({ level: Level.error, msg: exception });
    };
  })
  .run(
    (
      $window,
      $rootScope,
      UserService,
      BotGQService,
      StoreService,
      CacheControlService,
      $q,
      StatisticService,
      $injector,
    ) => {
      'ngInject';

      // save $injector for angular2react (omnibox)
      lazyInjector.$injector = $injector;

      setAngularContext($rootScope);
      setAngularRouterContext($rootScope);

      $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
        const botId = toParams.bot_id;
        // TODO routing проверить, что работает вообще
        if (botId && botId !== $rootScope.stateParams.botId) {
          BotGQService.setBotLastOpenedDate(botId);
        }
      });

      /* eslint-disable-next-line no-param-reassign */
      $rootScope.RoleService = {
        canView,
        canEdit,
        loadPermissionsMemoized,
      };

      /* eslint-disable-next-line no-param-reassign */
      $rootScope.isAutomateEnabled = false;
      getAdminFeatures().then((adminFeatures) => {
        /* eslint-disable-next-line no-param-reassign */
        $rootScope.isAutomateEnabled = !adminFeatures.disable_automate_tab;
      });

      onPermissionsUpdate(() => {
        $rootScope.$evalAsync();
      });

      CacheControlService.startPreload();

      if (isMobileDevice()) {
        const body = window.angular.element(
          document.getElementsByTagName('body')[0],
        );

        window.angular.element($window).on('resize', () => {
          const mws = body.hasClass('mobile-warring-show');
          const h =
            window.angular.element($window).height() + 64 - (mws ? 110 : 0);
          if (mws) {
            body.css('height', h);
          }
        });

        window.angular.element($window).trigger('resize');
      }

      preloadServerStorage();

      if (window.i18next) {
        subscribeForLanguageChange($rootScope);
      } else {
        i18nEmitter.on(I18nEvent.initialized, () =>
          subscribeForLanguageChange($rootScope),
        );
      }
    },
  ).name;
