import { isWhiteLabelDomain } from './utils/WhiteLabelUtils';
import { sendEvent } from './utils/Analytics';

export default class AppController {
  constructor($scope, $rootScope, Constants) {
    'ngInject';

    this.$scope = $scope;
    this.Constants = Constants;

    $scope.showPreloader = true;

    $scope.$on('$stateChangeSuccess', this.initStates);

    this.isWhiteLabelDomain = isWhiteLabelDomain;
  }

  sendEvent = (item) => {
    sendEvent({
      category: 'header button',
      action: 'click',
      label: item,
      propertyBag: {
        button: item,
      },
    });
  };
}
