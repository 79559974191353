import { getAttributeDescription } from '@utils/AttributesUtils/AttributesDescription';
import {
  getAttributesAsync,
  getAttributesQueryObservable,
} from '@utils/AttributesUtils/AttributesUtils';
import { Platform } from '../@types/globalTypes';
import { VariableSuggestType } from './utils/AttributesUtils/AttributesUtilsTypes';
import { UTC_LIST } from '@utils/timezone';

const SYSTEM_ALERTS = {
  broadcast: {
    '2017-03-22': {
      tooltip:
        'Broadcasts may not have reached some users due to an infrastructure update from March 22nd to 24th.',
      text: 'Delivery update',
    },
    '2017-03-23': {
      tooltip:
        'Broadcasts may not have reached some users due to an infrastructure update from March 22nd to 24th.',
      text: 'Delivery update',
    },
    '2017-03-24': {
      tooltip:
        'Broadcasts may not have reached some users due to an infrastructure update from March 22nd to 24th.',
      text: 'Delivery update',
    },
    '2017-05-18': {
      tooltip:
        'From 5:30 AM GMT to approximately 4 PM GMT Facebook stopped sending us read receipts due to a bug on their end. This may have affected the read rate stats for broadcasts sent in that timeframe (note, the broadcast functionality itself was not affected - just statistics).',
      text: 'Delivery update',
    },
  },
  analytics: {
    user_activity: {
      '2017-03-22':
        'Broadcasts may not have reached some users due to an infrastructure update from March 22nd to 24th.',
      '2017-05-18':
        'From 5:30 AM GMT to approximately 4 PM GMT Facebook stopped sending us read receipts due to a bug on their end. This may have affected the read rate stats for broadcasts sent in that timeframe (note, the broadcast functionality itself was not affected - just statistics).',
    },
    daily: {
      '2017-06-27':
        'Users who got acquired via JSON-generated Facebook ads but did not interact with the bot were incorrectly shown as Reachable. This drop in Reachable users represents an adjustment to take out these subscribers.',
    },
    total_users: {
      '2017-06-27':
        'Users who got acquired via JSON-generated Facebook ads but did not interact with the bot were incorrectly shown as Reachable. This drop in Reachable users represents an adjustment to take out these subscribers.',
    },
  },
};

export class Store {
  constructor($cookies, $q, $injector, $rootScope) {
    'ngInject';

    this.$cookies = $cookies;
    this.$q = $q;
    this.$injector = $injector;
    this.$rootScope = $rootScope;

    this.attributes = [];

    $rootScope.$on('$stateChangeSuccess', (event, toState, toParams) => {
      if (toParams.botId) {
        if (this.attributesSubscription) {
          this.attributesSubscription.unsubscribe();
        }
        this.attributesSubscription = getAttributesQueryObservable(
          $rootScope.stateParams.botId,
          VariableSuggestType.template,
          Platform.facebook,
        ).subscribe((allBotAttributesForSuggest) => {
          this.attributes = allBotAttributesForSuggest;
        });
      }
    });
  }

  get(key) {
    return this[key];
  }

  set(key, value) {
    this[key] = value;
  }

  // eslint-disable-next-line class-methods-use-this
  getMainUrl() {
    return `${window.location.protocol}//${window.location.hostname.replace(
      'dashboard.',
      '',
    )}`;
  }

  // eslint-disable-next-line class-methods-use-this
  getApiUrl() {
    return '/api';
  }

  getFullApiUrl() {
    return `${window.location.protocol}//${
      window.location.hostname
    }${this.getApiUrl()}`;
  }

  // eslint-disable-next-line class-methods-use-this
  getAppId() {
    const appId = window.CHATFUEL_CONFIG.APP_ID;
    if (!appId) {
      throw new Error("APP ID don't set");
    }
    return appId;
  }

  // eslint-disable-next-line class-methods-use-this
  getUTCList() {
    return UTC_LIST;
  }

  isSystemAttribute(attributeName) {
    return !!this.attributes.find(
      (attribute) =>
        attribute.name === attributeName && attribute.type === 'system',
    );
  }

  async getPromiseIsSystemAttribute(attributeName) {
    await getAttributesAsync(
      this.$rootScope.stateParams.botId,
      undefined,
      Platform.facebook,
    );
    return this.isSystemAttribute(attributeName);
  }

  // eslint-disable-next-line class-methods-use-this
  getSystemAttributeTooltip(attributeName) {
    return getAttributeDescription(attributeName) ?? attributeName;
  }

  // eslint-disable-next-line class-methods-use-this
  getErrorsData() {
    return SYSTEM_ALERTS;
  }

  _injectUserService() {
    return this.$injector.get('UserService');
  }
}
